export default function sketch(p){
	let w = document.querySelector('.p5canvas').offsetWidth;
	let h = document.querySelector('.p5canvas').offsetHeight;
	let time = 0;

	p.setup = function(){
		p.createCanvas(w, h);
	}

	p.draw = function(){
		if(p.frameCount % 12 === 0){
			p.background(255, 40);
		}
		p.stroke(180, p.random(200, 220), p.random(200, 255), p.random(40, 120));
		p.noFill();
		drawWave(time);
		time+=0.01;
	}

	function drawWave(time){
		p.translate(0, h/1.4);
		let numVertex = 28;
		p.beginShape();

		let sinAddition = 0;
		for(let i = 0; i < numVertex; i++){
			let x = p.map(i, 0, numVertex, -80, w+80);
			let yMax = h/7;
			let yNoise = p.map(p.noise(sinAddition, time), 0, 1, -yMax*1.4, yMax*1.4);
			let y = p.sin(sinAddition+time) * yMax + yNoise;
			p.curveVertex(x, y);
			sinAddition+=0.2;
		}
		p.endShape();
	}

	p.windowResized = function(){
		p.resizeCanvas(p.windowWidth, p.windowHeight);
		w = document.querySelector('.p5canvas').offsetWidth;
		h = document.querySelector('.p5canvas').offsetHeight;
		p.createCanvas(w, h);
	}
}