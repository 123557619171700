import React, {Component} from "react";
import {Helmet} from 'react-helmet';
import PageHeader from '../Components/PageHeader';
import {inAnim} from '../Util';
import headerImage from '../assets/header_about.jpg';
import prof1 from '../assets/about_prof1.jpg';
import prof2 from '../assets/about_prof2.jpg';
import '../scss/About.scss';
import GTM from 'react-gtm-module';

//GTM-5WPXJ5G
const tagManagerArgs = {
    gtmId: 'GTM-5WPXJ5G',
    dataLayer: {
    		dataLayerName: 'About',
    }
}


export default class About extends Component{

	componentDidMount(){
		document.oncontextmenu = function(){ return false; };
		document.onselectstart = function(){ return false; };
		document.onmousedown = function(){ return false; };
		GTM.initialize(tagManagerArgs)
		inAnim();
	}
	render(){
		return(
			<div id="about">
				<Helmet>
				  <title>Shogo Film | About</title>
				  <meta name="description" content="アメリカ、サンタバーバラ大学でフィルムと写真を専攻。日本山岳写真協会展入選、第22回新潟写真協会展入選など多数の受賞歴" />
				</Helmet>
				<PageHeader title="About" img={headerImage} />
				<main className="sub_clm">
					<h3 className="en m">PROFILE</h3>
					<p>寺田彰吾　石川県金沢市生まれ。<br />インドネシアや世界１０数カ国を旅した後、米サンタバーバラ大学でフィルムと写真を学び、卒業後はハワイで修行を積む。サーフィンの聖地オアフ島のノースシュワで撮影し、独自の方法で印刷したパイプラインの写真は、ハワイのサーフショップ、レストラン、カフェで展示され、人気を博す。また、世界で有名なサーフィン情報サイトMagicSeaweed.comでは、フォト・オブ・ザ・デイに数回輝く。大自然の魅力を伝えることで、疲弊した日本を元気づけようと、世界を股にかけて活動中。</p>

					<p>All my life I've been surrounded by cameras. I grew up taking pictures and making videos in a small snowy called Ishikawa, Japan. I had fun especially during the winter since I made snowboarding videos for my friends. But Ishikawa was too small for my adventurous mind. When I turned 18 I decided to see the world. With my camera on my side I visited many different countries and learned different cultures. It opened up my mind. In Sri Lanka, I discovered surfing. I got addicted to this beautiful spot, and my ambition grew like the huge waves of Indonesia. I wanted to know more about the world, surfing, photography and filmmaking. California was my next destination. After graduating from Film Production Institute at Santa Barbara City College, I'm on the road again, traveling around the world.</p>

						<img src={prof2} alt="" />
				</main>

				<aside>
					<div className="sub_clm">
					<h3>受賞歴</h3>
						<table>
							<tr>
								<th>2013 — 2015</th>
								<td>世界的に有名なサーフィン情報サイト<br />Magic Sea weedの写真コンテストで<br />数回のグランプリ受賞</td>
							</tr>

							<tr>
								<th rowspan="2">2018</th>
								<td>白山開山1300年フォトコンテスト<br />日本山岳写真協会展　入選</td>
							</tr>
							<tr>
								<td>第22回新潟写真協会展　入選</td>
							</tr>
						</table>
						<img src={prof1} alt="" />
					</div>
				</aside>
			</div>
		);

	}

}