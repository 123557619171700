import {TimelineMax} from 'gsap';
import ScrollToPlugin from "gsap/ScrollToPlugin";


export function inAnim(){
	let tl = new TimelineMax();
	let content = document.getElementById('content');
	let loader = document.getElementById('loader');

	tl
	.add(()=>{
		window.scrollTo(0, 0);
	})
	.to(loader, .3, {
		opacity: 0,
	}, 's1')
	.add(()=>{
		content.classList.add('ready');
	}, 's1')
	.set(loader, {
		display: 'none',
	});
}

export function outAnim(func){
	let tl = new TimelineMax();
	let content = document.getElementById('content');
	let loader = document.getElementById('loader');

	tl.set(loader, {
		display: 'block',
	})
	.to(loader, .1, {
		opacity: 1,
	}, 's1')
	.add(()=>{
		content.classList.remove('ready');
		setTimeout(()=>{func();}, 800);
	}, 's1');


}
