import React, {Component} from "react";
import config from '../config';
import {PhotoSwipeGallery} from 'react-photoswipe';
import 'react-photoswipe/lib/photoswipe.css';
import {inAnim} from '../Util';
import '../scss/Photogallery.scss';

import Masonry from 'react-masonry-component';


export default class PhotoGallery extends Component{
	constructor(props){
		super(props);
		this.state = {
			all:[],
			galleryType: this.props.type,
			surf: {},
			wedding: {},
			tourism: {},
			landscape: {},
			ready: false,
			items: [],
		}
	}

	componentDidMount(){
		this.fetchGallery();
	}

	fetchGallery(){
		let url = config.apiPath + 'photo_gallery';
		fetch(url).then(response=>response.json())
		.then(response=>{
			this.setState({
				"all" : response
			});
		})
		.then(()=>{
			for(let i = 0; i < this.state.all.length; i++){
				let pg = this.state.all[i];
				if(pg.acf.gallery_type == 'surf'){
					this.setState({"surf": pg});
				}else if(pg.acf.gallery_type == 'wedding'){
					this.setState({"wedding": pg});
				}else if(pg.acf.gallery_type == 'tourism'){
					this.setState({"tourism": pg});
				}else if(pg.acf.gallery_type == 'landscape'){
					this.setState({"landscape": pg}, ()=>{});
				}
			}
		})
		.then(()=>{
			this.setState({"ready": true});
			inAnim();
		});
	}

	getThumbnailContent(item){
		return (
			<img src={item.thumbnail} />
		)
	}

	render(){
		//https://www.npmjs.com/package/react-photoswipe
		let target;
		let gallery = [];
		if(this.state.ready){

			if(this.state.galleryType == 'surf'){
				target = this.state.surf.acf;
			}else if(this.state.galleryType == 'wedding'){
				target = this.state.wedding.acf;
			}else if(this.state.galleryType == 'tourism'){
				target = this.state.tourism.acf;
			}else if(this.state.galleryType == 'landscape'){
				target = this.state.landscape.acf;
			}

			for(let key in target){
				if(key.includes('image')){
					if(target[key] != false){
						gallery.push(target[key].sizes);
					}
				}
			}

			for(let i = 0; i < gallery.length; i++){
				let obj = {
					src: gallery[i].large,
					thumbnail: gallery[i].medium_large,
					w: gallery[i]['large-width'],
					h: gallery[i]['large-height'],
				}
				if(gallery.length > this.state.items.length){
					this.state.items.push(obj);
				}
			}


		}

		return(
			<div className="pg">
				<PhotoSwipeGallery
					id="psg"
					options={{shareEl: false}}
					items={this.state.items}
					thumbnailContent={this.getThumbnailContent}
				/>
			</div>
		);

	}

}