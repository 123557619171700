import React, {Component} from "react";
import {Helmet} from 'react-helmet';
import PageHeader from '../Components/PageHeader';
import Aside from '../Components/Aside';
import {inAnim} from '../Util';
import headerImage from '../assets/header_contact.jpg';
import '../scss/Contact.scss';
import GTM from 'react-gtm-module';

//GTM-5WPXJ5G
const tagManagerArgs = {
    gtmId: 'GTM-5WPXJ5G',
    dataLayer: {
    		dataLayerName: 'Contact',
    }
}


let state = {
	name: '',
	email: '',
	emailError: false,
	tel: '',
	title: '',
	message: '',
	showError: false,
	isReady: false
}

export default class Contact extends Component{
	constructor(props){
		super(props);
		this.state = state;
		this.stateChange = this.stateChange.bind(this);
		this.checkValue = this.checkValue.bind(this);
		this.checkForm = this.checkForm.bind(this);
	}

	componentDidMount(){
		document.oncontextmenu = function(){ return true; };
		document.onselectstart = function(){ return true; };
		document.onmousedown = function(){ return true; };
		GTM.initialize(tagManagerArgs)
		inAnim();
	}

	stateChange(e){
		switch(e.target.name){
			case 'name':
				this.setState({name: e.target.value});
				break;
			case 'email':
				this.setState({email: e.target.value});
				this.checkValue( /^\S+@\S+\.\S+$/, e.target.value)?
				this.setState({emailError:false}) :
				this.setState({emailError:true});
				break;
			case 'tel':
				this.setState({tel: e.target.value});
				break;
			case 'title':
				this.setState({title: e.target.value});
				break;
			case 'message':
				this.setState({message: e.target.value});
				break;
		}
	}

	checkValue(rg, value){
		return rg.test(value);
	}

	checkForm(e){
		e.preventDefault();
		//is form ready?
		if(
			!this.state.name == ""
			&& !this.state.email == ""
			&& !this.state.emailError
			&& !this.state.message == ""
		){
			console.log('ready');
			document.forms["hidden"].submit();
			this.setState({showError: false});
		}else{
			this.setState({showError: true});
		}
	}

	render(){
		return (
			<div id="contact">
				<Helmet>
				  <title>Shogo Film | Contact</title>
				  <meta name="description" content="お仕事のご依頼、ご相談などなんでもお気軽にご連絡ください。" />
				</Helmet>
				<PageHeader title="Contact" img={headerImage} />
				<main className="sub_clm">
					<h3 className="en m">Feel free to contact me!</h3>
					<p>お仕事のご依頼・ご相談など何かございましたら<br />フォームもしくはEメールにてお気軽にご連絡ください。</p>
					<a href="mailto:shogofilm@gmail.com">Email : shogofilm@gmail.com</a>
				</main>
				<form id="draft" className="sub_clm">
					<div className="clearfix">
						<div className="left">
							<input 
							onChange={this.stateChange}
							type="text" 
							name="name" 
							placeholder="お名前　※必須" 
							/>
							{this.state.showError && this.state.name == "" &&
								<p>お名前が未入力です</p>
							}

							<input 
							onChange={this.stateChange}
							type="email" 
							name="email" 
							placeholder="メールアドレス　※必須" 
							/>
							{this.state.showError && this.state.emailError &&
								<p>メールの形式をご確認ください</p>
							}
							{this.state.showError && this.state.email == "" &&
								<p>メールアドレスが未入力です</p>
							}

							<input 
							onChange={this.stateChange}
							type="tel" 
							name="tel" 
							placeholder="電話番号　半角" 
							/>

							<input 
							onChange={this.stateChange}
							type="text" 
							name="title" 
							placeholder="ご用件" 
							/>
						</div>
						<div className="right">
							<textarea 
							onChange={this.stateChange}
							name="message" 
							rows="4"
							placeholder="メッセージ　※必須" 
							/>
							{this.state.showError && this.state.message =="" &&
								<p>メッセージが未入力です</p>
							}
						</div>
					</div>
					<input
						type="submit"
						placeholder="送　信"
						value="送信"
						onClick={this.checkForm}
					/>
				</form>
				<form id="hidden"
				action="https://submit-form.com/1ad59f42-a62f-4e94-8a46-ac214575546f"
				method="POST">
					<input type="hidden" name="_redirect" value="https://shogofilm.com/thanks.html" />
					<input
						type="hidden"
						name="name"
						value={this.state.name}
					/>
					<input
						type="hidden"
						name="email"
						value={this.state.email}
					/>
					<input
						type="hidden"
						name="tel"
						value={this.state.tel}
					/>
					<input
						type="hidden"
						name="title"
						value={this.state.title}
					/>
					<input
						type="hidden"
						name="message"
						value={this.state.message}
					/>
				</form>
				<Aside title="Workflow" sub="製作の主な流れ" photography={true}>
					<ol>
						<li>
							<h4>1.お問合せ・お見積り</h4>
							<p>お問合せフォームやメール、電話にて見積り依頼を承ります。<br />※お見積り無料です</p>
						</li>
						<li>
							<h4>2.打合せ</h4>
							<p>撮影のイメージやロケーション日程などをご相談しながら計画していきます。</p>
						</li>
						<li>
							<h4>3.撮影制作〜完成 </h4>
							<p>撮影・編集を行います。</p>
						</li>
						<li>
							<h4>4.最終確認→納品</h4>
							<p>本制作された映像・写真の最終確認後、お振込みをお願いします。<br />入金の確認がとれましたら、完成品を納品させていただきます。</p>
						</li>
					</ol>
				</Aside>
			</div>
		);//end return
	}//end render

}