import React, {Component} from "react";
import {Helmet} from 'react-helmet';
import GTM from 'react-gtm-module';
const tagManagerArgs = {
    gtmId: 'GTM-5WPXJ5G',
    dataLayer: {
    		dataLayerName: 'Thanks',
    }
}


export default class Thanks extends Component{
	componentDidMount(){
		GTM.initialize(tagManagerArgs)
	}
	render(){
		return (
			<div>
				<Helmet>
				  <title>Shogo Film | Contact</title>
				</Helmet>
				THANK YOU!
			</div>
		);//end return
	}//end render

}