import React, {Component} from "react";

export default class PageHeader extends Component{
	constructor(props){
		super(props);
	}

	render(){
		let containerCss = {
			position: 'relative',
			zIndex: 10,
		}
		let bgCss = {
			position: 'absolute',
			top: 0, right: 0, bottom: 0, left: 0,
			backgroundImage: 'url(' + this.props.img + ')',
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
			backgroundPosition:'center center',
			zIndex: 0,
			opacity: .5,
		};
		let hCss = {
			position: 'relative',
			zIndex: 10,
			padding: '3.4em 0 1.4em',
		}

		return(
			<div id="page-header" className="main_clm" style={containerCss}>
				<h2 className="en" style={hCss}>{this.props.title}</h2>
				{this.props.sub &&
					<h3>{this.props.sub}</h3>
				}
				<div style={bgCss}></div>
			</div>
		);
	}

}