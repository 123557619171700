import React, {Component} from "react";
import {Helmet} from 'react-helmet';
import {withRouter} from 'react-router-dom';
import PageHeader from '../Components/PageHeader';
import PhotoGallery from '../Components/PhotoGallery';
import {inAnim, outAnim} from '../Util';
import {TweenMax, TimelineMax} from 'gsap';
import Aside from '../Components/Aside';
import More from '../Components/More';
import config from '../config';

import Sticky from 'react-sticky-el';

import headerImage1 from '../assets/header_pg1.jpg';
import headerImage2 from '../assets/header_pg2.jpg';
import headerImage3 from '../assets/header_pg3.jpg';
import headerImage4 from '../assets/header_pg4.jpg';
import '../scss/Photography.scss';


import GTM from 'react-gtm-module';
const tagManagerArgs = {
    gtmId: 'GTM-5WPXJ5G',
    dataLayer: {
    		dataLayerName: 'Photography',
    }
}

class Photography extends Component{
	constructor(props){
		super(props);
		this.state = {
			'section' : 'sports',
		}
	}

	componentDidMount(){
		document.oncontextmenu = function(){ return false; };
		document.onselectstart = function(){ return false; };
		document.onmousedown = function(){ return false; };
		GTM.initialize(tagManagerArgs)
		if(!this.props.section)return;
		this.setState({
			'section' : this.props.section
		});

	}

	catChange(to){
		let tl = new TimelineMax();
		let content = document.getElementById('content');
		let loader = document.getElementById('loader');
		tl
		.set(loader, {display: 'block'})
		.to(loader, .1, {opacity: 1}, 's1')
		.add(()=>{content.classList.remove('ready')})
		.add(()=>{
			setTimeout(()=>{
				this.setState({'section' : to})
				inAnim();
			}, 700);
		});
	}

	render(){
		let bg, sc, wc, tc, lc;
		if(this.state.section == 'sports'){
			bg = headerImage1;
			sc = 'current';
		}else if(this.state.section == 'wedding'){
			bg = headerImage2;
			wc = 'current';
		}else if(this.state.section == 'tour'){
			bg = headerImage3;
			tc = 'current';
		}else if(this.state.section == 'landscape'){
			bg = headerImage4;
			lc = 'current';
		}

		return(
			<div id="pg">
				<Helmet>
				  <title>Shogo Film | PhotoGraphy</title>
				  <meta name="description" content="全国各地のサーフィン大会やスポーツイベントなどの出張撮影からウェディングの前撮り撮影などフォトグラファーとして幅広く活動しています。" />
				</Helmet>
				<PageHeader title="Photography" img={bg} />
				<div className="container">
					<nav>
						<Sticky>
						<ul className="en">
							<li className={sc} onClick={()=>this.catChange('sports')}>SURF / SPORTS</li>
							<li className={wc} onClick={()=>this.catChange('wedding')}>WEDDING</li>
							<li className={tc} onClick={()=>this.catChange('tour')}>PHOTO TOUR</li>
							<li className={lc} onClick={()=>this.catChange('landscape')}>LANDSCAPE</li>
							<li className="contact">
								<More text="Contact" to="/contact" />
								<p className="contact">ご質問等ございましたら<br />お気軽にご連絡ください。</p>
							</li>
						</ul>
						</Sticky>
					</nav>
					{this.state.section == 'sports' &&
						<div className="sports">
							<section>
								<h3 className="en m">Surf / Sports</h3>
								<p>プロ・アマを問わず自然の中で輝く人々の姿を撮り続けています。<br />大会の撮影や個人・チームのプロモーション撮影など、全国各地ご希望場所に<br />出張可能ですのでお気軽にご相談ください。<br /><a href="https://shogofilm.thebase.in/" target="blank">こちらのページ</a>からもオーダーいただけます。</p>
								<PhotoGallery type="surf" />
							</section>
							<Aside title="Service Price" sub="撮影料金" photography={true}>					
								<h4>サーフィン、スポーツ大会各種などの<br />出張撮影サービス</h4>
								<dl>
									<div className="clearfix">
										<dt>1名様</dt>
										<dd>¥20,000</dd>
									</div>
									<div className="clearfix">
										<dt>2名様〜</dt>
										<dd>¥20,000 + ¥5,000 x 人数<span>例）2名様¥30,000、　3名様¥35,000</span></dd>
									</div>

								</dl>
								<p className="caution">※目安2時間程度。コンディションの状況で変わります。<br />※全て税込表記です。</p>

								<h4>交通費（出張撮影サービス）</h4>
								<p>羽咋市内無料。羽咋市を除く石川県内は半額負担。<br />石川県外は全額お客様負担となっております。</p>

								<h4>大会&イベントムービー製作（60秒まで）</h4>
								<dl>
									<div className="clearfix sub">
										<dt>企画・シナリオ</dt>
										<dd>¥25,000</dd>
									</div>
									<div className="clearfix sub">
										<dt>撮影</dt>
										<dd>¥25,000</dd>
									</div>
									<div className="clearfix sub">
										<dt>編集</dt>
										<dd>¥25,000</dd>
									</div>
									<div className="clearfix total">
										<dt>合計</dt>
										<dd>¥75,000</dd>
									</div>
								</dl>

								<h4>交通費（大会&イベントムービー製作）</h4>
								<p>石川県内無料。県外は実費請求となります。</p>
							</Aside>
						</div>
					}

					{this.state.section == 'wedding' &&
						<div className="wedding">
							<section>
								<h3 className="en m">Wedding</h3>
								<p>羽咋の豊かな自然や、金沢の歴史と趣ある景観を活かして、<br />自然の雄大さや神聖な空気をまとった「一生の記念」を撮影します。</p>
								<PhotoGallery type="wedding" />
							</section>
							<Aside title="Service Price" sub="撮影料金" photography={true}>
								<h4>前撮り写真（約50枚）</h4>
								<dl>
									<div className="clearfix">
										<dt>料金</dt>
										<dd>¥35,000〜<span>土日祝 + ¥12,000</span></dd>
									</div>
								</dl>
								<p className="caution">※衣装、メイク代は含まれておりません。<br />※オプションなどはご相談ください。</p>
								
								<h4>二次会スナップ写真</h4>
								<dl>
									<div className="clearfix">
										<dt>料金</dt>
										<dd>¥35,000〜</dd>
									</div>
								</dl>

								<h4>写真・動画（事前製作）</h4>
								<dl>
									<div className="clearfix">
										<dt>プロフィールムービー</dt>
										<dd>¥35,000 ~</dd>
									</div>
									<div className="clearfix">
										<dt>オープニングムービー</dt>
										<dd>¥35,000 ~</dd>
									</div>
									<div className="clearfix">
										<dt>エンドロールムービー</dt>
										<dd>¥35,000 ~</dd>
									</div>
									<div className="option">
										<h5>オプション</h5>
										<div className="clearfix">
											<dt>ロケーション撮影</dt>
											<dd>¥75,000 ~</dd>
										</div>
									</div>
								</dl>
								<p className="caution">※衣装、メイク代は含まれておりません。<br />※オプションなどはご相談ください。</p>

								<h4>交通費</h4>
								<p>石川県内無料。県外は実費請求となります。</p>
							</Aside>
						</div>
					}

					{this.state.section == 'tour' &&
						<div className="tour">
							<section>
								<h3 className="en m">Photo Tour</h3>
								<p className="english">I offer variety of photo tours to maximize your experience in Kanazawa. <br />I had been showing my friends from overseas the unique scenic spots and activities that only locals would know and they really loved it so, since then I started photo tour to spread the good vibes much wider. along the way I take the photographs optimised by living experience as a local, all you need to do is just enjoy the scenery and experience.<br />Feel free to contact me if you want to know more about Kanazawa or the photo tours.<br />by the way, if you would like to surf in Ishikawa area. I would be so happy to show you the spots as well.</p>
								<p>国外から観光で来られる方々のために、英語で案内をしながらの写真撮影を行っています。また、地元の方々のためには英訳付のインバウンド対策のプロモーション動画や広告企画などをサポートいたします。<br />ご興味のある方は、お気軽にご連絡ください。</p>
								<PhotoGallery type="tourism" />
							</section>
							<Aside title="Service Price" sub="撮影料金" photography={true}>
								<h4>Higashiyama Strolling Course</h4>
								<dl>
									<div className="clearfix">
										<dt>1 Person</dt>
										<dd>¥10,000</dd>
									</div>
									<div className="clearfix">
										<dt>2 Person or more</dt>
										<dd>10,000 + ¥2,000 x Persons<span>E.g. 2 person ¥12,000, 3 person ¥14,000</span></dd>
									</div>
								</dl>

								<h4>Kanazawa Strolling Course</h4>
								<p>Higashiyama — Kenroku garden — Kanazawa castle — 21st century art museum</p>
								<dl>
									<div className="clearfix">
										<dt>1 Person</dt>
										<dd>¥20,000</dd>
									</div>
									<div className="clearfix">
										<dt>2 Person or more</dt>
										<dd>¥20,000 + ¥4,000 x Persons<span>E.g. 2 person ¥24,000, 3 person ¥28,000</span></dd>
									</div>
								</dl>

								<h4>Nature Strolling Course(Half day)</h4>
								<p>Spring — Autumn<br />Hiking to Mt.Iouzen the locally beloved mountain.</p>
								<dl>
									<div className="clearfix">
										<dt>1 Person</dt>
										<dd>¥30,000</dd>
									</div>
									<div className="clearfix">
										<dt>2 Person or more</dt>
										<dd>¥30,000 + ¥5,000 x Persons<span>E.g. 2 person ¥35,000, 3 person ¥40,000</span></dd>
									</div>
								</dl>
								<p className="caution">Course may be subject to change due to weather and trail condition.</p>


								<h4>東山コース</h4>
								<p>東山交番前集合—東山散策—集合地にて解散</p>
								<dl>
									<div className="clearfix">
										<dt>1名様</dt>
										<dd>¥10,000</dd>
									</div>
									<div className="clearfix">
										<dt>2名様〜</dt>
										<dd>¥10,000 + ¥2,000 x 人数<span>例）2名様¥12,000、　3名様¥14,000</span></dd>
									</div>
								</dl>

								<h4>金沢散歩コース</h4>
								<p>東山交番前集合—東山—兼六園—金沢城—21世紀美術館—現地解散</p>
								<dl>
									<div className="clearfix">
										<dt>1名様</dt>
										<dd>¥20,000</dd>
									</div>
									<div className="clearfix">
										<dt>2名様〜</dt>
										<dd>¥20,000 + ¥4,000 x 人数<span>例）2名様¥24,000、　3名様¥28,000</span></dd>
									</div>
								</dl>

								<h4>自然満喫コース（半日）</h4>
								<p>金沢駅集合—医王山トレッキング—集合地にて解散</p>
								<dl>
									<div className="clearfix">
										<dt>1名様</dt>
										<dd>¥30,000</dd>
									</div>
									<div className="clearfix">
										<dt>2名様〜</dt>
										<dd>¥30,000 + ¥5,000 x 人数<span>例）2名様¥35,000、　3名様¥40,000</span></dd>
									</div>
								</dl>
								<p className="caution">季節、天候によってコース内容が変わる場合がございます。</p>
							</Aside>
						</div>
					}

					{this.state.section == 'landscape' &&
						<div className="landscape">
							<section>
								<h3 className="en m">Landscape</h3>
								<p>国内、国外で旅をしながら風景の写真を収め続けています。独自の方法で出力した大型写真の展示販売会なども不定期に開催しております。</p>
								<PhotoGallery type="landscape" />
							</section>
						</div>
					}
				</div>
			</div>
		);
	}

}

export default withRouter(Photography);