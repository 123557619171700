import React, {Component} from 'react';
import Loader from '../Components/Loader';
import {Helmet} from 'react-helmet';
import PageHeader from '../Components/PageHeader';
import {inAnim, outAnim} from '../Util';
import {TweenMax, TimelineMax} from 'gsap';
import config from '../config';
import '../scss/News.scss';
import {
	FaAngleDoubleRight,
	FaAngleDoubleLeft
} from 'react-icons/fa';

import headerImage from '../assets/header_news.jpg';
import GTM from 'react-gtm-module';
const tagManagerArgs = {
    gtmId: 'GTM-5WPXJ5G',
    dataLayer: {
    		dataLayerName: 'News',
    }
}

class News extends Component{
	constructor(props){
		super(props);
		this.state = {
			news: [],
			single: {},
			totalPages: 0,
			currentPage: 1,
			hasNext:false,
			hasPrev: false,
			wpLoaded: false,
		}
	}

	componentDidMount(){
		document.oncontextmenu = function(){ return false; };
		document.onselectstart = function(){ return false; };
		document.onmousedown = function(){ return false; };
		GTM.initialize(tagManagerArgs)
		this.fetch_wp_news();
	}

	fetch_wp_news(){
		let url = config.apiPath + 'posts';
		fetch(url)
		.then(response => {
			let totalPages = response.headers.get('x-wp-totalpages');
			this.setState({'totalPages': totalPages});
			if(this.state.currentPage < totalPages){
				this.setState({'hasNext': true});
			}
			return response.json();
		})
		.then(response => {
			this.setState({'news':response});
			this.setState({'wpLoaded':true});
			inAnim();
		});
	}

	pageChange(pagenumber){
		let url = config.apiPath + 'posts?page=' + pagenumber;
		fetch(url)
		.then(response => {
			return response.json();
		})
		.then(response => {
			this.setState({
				'news': response,
				'currentPage': pagenumber,
			})
			let tl = new TimelineMax();
			let content = document.querySelector('#news_index ul');
			tl
			.to(content, .4, {
				opacity:0,
			})
			.to(window, .6, {
				scrollTo: {y:0}
			}, '-=0.4')
			.to(content, .2, {
				opacity: 1,
			})
		})
	}

	getSinglePost(pageId){
		let tl = new TimelineMax();
		let content = document.getElementById('content');
		let loader = document.getElementById('loader');
		tl
		.set(loader, {display: 'block'})
		.to(loader, .1, {opacity: 1}, 's1')
		.add(()=>{content.classList.remove('ready')})
		.add(()=>{
			let url = config.apiPath + 'posts/' + pageId;
			fetch(url)
			.then(response => {
				return response.json();
			})
			.then(response => {
				this.setState({
					'isSingle': true,
					'single' : response
				});
				setTimeout(()=>{inAnim();}, 700);
			})
		});

	}

	toIndex(){
		let tl = new TimelineMax();
		let content = document.getElementById('content');
		let loader = document.getElementById('loader');
		tl
		.set(loader, {display: 'block'})
		.to(loader, .1, {opacity: 1}, 's1')
		.add(()=>{content.classList.remove('ready')})
		.add(()=>{
			setTimeout(()=>{
				this.setState({'isSingle' : false})
				inAnim();
			}, 700);
		});
	}

	render(){
		let news = this.state.news.map((news, index) => {
			let rawDate = new Date(news.date_gmt);
			let hasEyecatch = news.better_featured_image ? true : false;
			let date = 
				rawDate.getFullYear(rawDate) + '年' 
				+ (rawDate.getMonth()+1) + '月'
				+ rawDate.getDate() + '日';
			return(
				<li key={index}>
					<time>{date}</time>
					<div className="right clearfix">
						<div className="content">
							<h3 dangerouslySetInnerHTML={{__html: news.title.rendered}}></h3>
							<div dangerouslySetInnerHTML={{__html: news.excerpt.rendered}}></div>
							<button onClick={()=>this.getSinglePost(news.id)}>Read more<FaAngleDoubleRight /></button>
						</div>
						<div className="eyecatch">
							{hasEyecatch && 
								<img src={news.better_featured_image.source_url} alt="" />
							}
						</div>
					</div>
				</li>
			);
		}); //end news


		let pageNation = [];
		for(let i = 1; i <= this.state.totalPages; i++){
			if(i === this.state.currentPage){
				pageNation.push(
					<span key={i} className="current">{i}</span>
				);
			}else{
				pageNation.push(
					<span key={i} onClick={()=>this.pageChange(i)}>{i}</span>
				);
			}
		}

		let single = this.state.single;

		if(!this.state.isSingle){
			//news index
			return (
				<div id="news_index">
					<Helmet>
					  <title>Shogo Film | News</title>
					  <meta name="description" content="最新のお知らせやブログなどこちらのページにてご覧いただけます。" />
					</Helmet>
					<PageHeader title="News" img={headerImage} />
						<main>
						<div className="side-bg"></div>
						<ul>{news}</ul>
						<div className="pagenation">{pageNation}</div>
						{!this.state.wpLoaded &&
							<Loader />
						}
					</main>
				</div>
			);
		}
		else{
			//news single
			let rawDate = new Date(single.date_gmt);
			let hasEyecatch = single.better_featured_image ? true : false;
			let date = 
				rawDate.getFullYear(rawDate) + '年' 
				+ (rawDate.getMonth()+1) + '月'
				+ rawDate.getDate() + '日';
			return(
				<div id="news_single">
					<Helmet>
					  <title>Shogo Film | News</title>
					  <meta name="description" content="最新のお知らせやブログなどこちらのページにてご覧いただけます。" />
					</Helmet>
					{hasEyecatch &&
						<PageHeader title="News" sub={single.title.rendered} img={single.better_featured_image.source_url} />
					}
					{!hasEyecatch &&
						<PageHeader title="News" sub={single.title.rendered} img={headerImage} />
					}
					<div className="sub_clm">
						<time>{date}</time>
						<div dangerouslySetInnerHTML={{__html:single.content.rendered}} />

						<div className="nav_ui">

							{single.prev_post &&
								<button className="prev" onClick={()=>this.getSinglePost(single.prev_post)}><FaAngleDoubleLeft /><span>Previous</span></button>
							}

							<button className="index" onClick={()=>this.toIndex()}>News一覧に戻る</button>

							{single.next_post &&
								<button className="next" onClick={()=>this.getSinglePost(single.next_post)}><span>Next</span><FaAngleDoubleRight /></button>
							}
						</div>
					</div>
				</div>
			);
		}
	}


}

export default News;