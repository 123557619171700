import React, {Component} from "react";
import bg1 from '../assets/bg_tile.png';
import More from '../Components/More';
import '../scss/Aside.scss';

export default class Aside extends Component{
	constructor(props){
		super(props);
		this.state = {
			photography: false,
		}
	}

	componentDidMount(){
		if(this.props.photography){
			this.setState({
				'photography' : true
			});
		}
	}

	render(){
		const bgCss = {
			backgroundImage: 'url(' + bg1 + ')',
			backgroundRepeat: 'repeat',
		}
		let aClass=this.state.photography ? "page pg" : "page sub_clm";

		return(
			<aside className={aClass} style={bgCss}>
				<div className="left">
					<h3 className="en m">
						{this.props.title}
						{this.props.sub &&
							<span>{this.props.sub}</span>
						}
					</h3>
					{!this.state.photography &&
						<div>
							<More text="Contact" to="/contact" />
							<p className="contact">ご質問等ございましたら<br />お気軽にご連絡ください。</p>
						</div>
					}
				</div>
				<div className="right">
					{this.props.children}
				</div>	
			</aside>
		);
	}

}
