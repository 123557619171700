import "babel-polyfill";
import React, { Component } from 'react';
import {Helmet} from 'react-helmet';
import {Route, Switch} from 'react-router-dom';
import logo from './logo.svg';
import './scss/App.scss';

import Loader from './Components/Loader';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Top from './Pages/Top';
import Photography from './Pages/Photography';
import Videography from './Pages/Videography';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Thanks from './Pages/Thanks';
import News from './Pages/News';
import TopPoster2 from './assets/poster_top2.png';


class App extends Component {
  render() {
    return (
      <div className="App">
        <Helmet>
          <title>Shogo Film</title>
          <meta name="description" content="石川県金沢市を中心に活動するフォトグラファー/ビデオグラファーです。自然やサーフィンを題材にした制作を得意としています。前撮り、プロモーション動画の制作、スポーツ大会への出張撮影など撮影の事ならなんでもご相談ください。" />
        </Helmet>
        <Loader />
        <Header />
        <Route render={(location) => (
          <div id="content">
            <Route exact path="/" component={Top} />
            <Route exact path="/photo" component={Photography} />
            <Route
              path="/photo/:section"
              render={({location, match}) => (
                <Photography section={match.params.section} />
              )}
            />
            <Route exact path="/video" component={Videography} />
            <Route exact path="/about" component={About} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/thanks" component={Thanks} />
            <Route exact path="/news" component={News} />
            <Footer />
          </div>
        )} />
      </div>
    );
  }
}

export default App;
