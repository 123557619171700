import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {TweenMax, TimelineMax} from 'gsap';
import {inAnim, outAnim} from '../Util';
import baseIcon from '../assets/base.svg'; 
import logo from '../assets/logo.svg'; 

import {
	FaFacebook,
	FaYoutube,
	FaVimeo,
	FaInstagram,
} from 'react-icons/fa';
import '../scss/Header.scss';

class Header extends Component{
	constructor(props){
		super(props);
	}

	componentDidMount(){
		document.getElementById('hamburger')
		.addEventListener('click', ()=>{
			this.hamburger();
		});
	}

	hamburger(){
		//is open ?
		let header = document.getElementById('header');
		let bar1 = document.querySelector('#hamburger span:nth-of-type(1)');
		let bar2 = document.querySelector('#hamburger span:nth-of-type(2)');
		let bar3 = document.querySelector('#hamburger span:nth-of-type(3)');
		let isOpen = header.classList.contains("open") ? true : false;
		//animate bar and toggle class
		let tl = new TimelineMax();
		if(isOpen){			tl
			.to(bar1, 0.3, {
				transformOrigin: '50% 50%',
				rotation: '0',
				top: 12
			}, 'rotate')
			.to(bar2, 0.2, {
				transformOrigin: '50% 50%',
				width: 24
			}, 'rotate')
			.to(bar3, 0.3, {
				transformOrigin: '50% 50%',
				rotation: '0',
				top: 24
			}, 'rotate')
			header.classList.remove('open');
		}else{
			tl
			.to(bar1, 0.3, {
				transformOrigin: '50% 50%',
				rotation: '45deg',
				top: 18
			}, 'rotate')
			.to(bar2, 0.2, {
				transformOrigin: '50% 50%',
				width: 0
			}, 'rotate')
			.to(bar3, 0.3, {
				transformOrigin: '50% 50%',
				rotation: '-45deg',
				top: 18
			}, 'rotate')
			header.classList.add('open');
		}
	}

	routeChange(to){
		if(this.props.location.pathname == to) return;
		if(to !== '/') this.hamburger();
		function change(){
			let path = this.props.location.pathname;
			this.props.history.push(to);
		}
		outAnim(change.bind(this));
	}

	render(){
		return(
			<div>
			<img id="logo" src={logo} alt="SHOGO FILM"
				onClick={()=>this.routeChange('/')}
			 />
			<div id="hamburger">
				<span></span>
				<span></span>
				<span></span>
			</div>
			<header id="header" className="en">
				<nav>
					<span>MENU</span>
					<ul className="menu">
						<li onClick={()=>this.routeChange('/photo')}>Photography</li>
						<li onClick={()=>this.routeChange('/video')}>Videography</li>
						<li onClick={()=>this.routeChange('/about')}>About</li>
						<li onClick={()=>this.routeChange('/news')}>News</li>
						<li onClick={()=>this.routeChange('/contact')}>Contact</li>
					</ul>
				</nav>
				<div className="follow_me">
				<span>F</span><span>O</span><span>L</span><span>L</span><span>O</span><span>W</span><span> </span><span>M</span><span>E</span>
				</div>
				<ul className="sns">
					<li>
						<a href="https://www.facebook.com/shogofilm" target="blank"><FaFacebook className="icon" /><span>Facebook</span></a>
					</li>
					<li>
						<a href="https://www.instagram.com/shogofilm/" target="blank"><FaInstagram className="icon" /><span>Instagram</span></a>
					</li>
					<li>
						<a href="https://www.youtube.com/user/Terasho1206"><FaYoutube className="icon" /><span>Youtube</span></a>
					</li>
					<li>
						<a href="https://vimeo.com/user4219287" target="blank"><FaVimeo className="icon" /><span>Vimeo</span></a>
					</li>
					<li>
						<a href="https://shogofilm.thebase.in" target="blank"><img className="base" src={baseIcon} alt="" /><span>Base</span></a>
					</li>
				</ul>
			</header>
			</div>
		);
	}
}

export default withRouter(Header);