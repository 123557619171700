import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {outAnim} from '../Util';

import '../scss/Footer.scss';


class Footer extends Component{
	constructor(props){
		super(props);
	}

	routeChange(to){
		if(this.props.location.pathname == to) return;
		function change(){
			let path = this.props.location.pathname;
			this.props.history.push(to);
		}
		outAnim(change.bind(this));
	}

	render(){
		return(
			<footer id="footer">
				<nav>
					<ul className="en">
						<li onClick={()=>this.routeChange('/')}>Home</li>
						<li onClick={()=>this.routeChange('/photo')}>Photography</li>
						<li onClick={()=>this.routeChange('/video')}>Videography</li>
						<li onClick={()=>this.routeChange('/about')}>About</li>
						<li onClick={()=>this.routeChange('/news')}>News</li>
						<li onClick={()=>this.routeChange('/contact')}>Contact</li>

					</ul>
				</nav>
				<p className="bold">©2018 Shogo Film</p>
			</footer>
		);

	}

}

export default withRouter(Footer);