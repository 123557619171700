import React, {Component} from 'react';

import '../scss/Loader.scss';

export default class Loader extends Component{

	render(){
		return(
			<div id="loader" className="sk-folding-cube bold">
			  <div className="sk-cube1 sk-cube"></div>
			  <div className="sk-cube2 sk-cube"></div>
			  <div className="sk-cube4 sk-cube"></div>
			  <div className="sk-cube3 sk-cube"></div>
			</div>
		);

	}


}
