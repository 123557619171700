import React, {Component} from "react";
import MQ from 'react-responsive';
import config from '../config';
import {inAnim} from '../Util';
import Youtube from 'react-youtube';
import Vimeo from 'react-vimeo';
import {TweenMax, TimelineMax} from 'gsap';
import '../scss/VideoList.scss';
import {IoIosCloseCircleOutline} from'react-icons/io';
import { MdAdd } from "react-icons/md";

export default class VideoList extends Component{
	constructor(props){
		super(props);
		this.state = {
			videos: [],
			vimeoThumbs: {},
			showVideo: false,
			videoPop: '',
			popId: null,
			randomId: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10),
		}
	}

	componentDidMount(){
		this.fetch_wp_video();
	}

	fetch_wp_video(){
		let max = this.props.max ? this.props.max : 3;
		let url;
		if(this.props.type == 'cm'){
			url = config.apiPath + 'video_cm?per_page=' + max;
		}else if(this.props.type == 'sports'){
			url = config.apiPath + 'video_sports?per_page=' + max;
		}
		
		fetch(url).then(response=>response.json())
		.then(response=>{
			this.setState({
				"videos" : response
			});

			//fetch vimeo thumb
			(function(){
				let vimeoThumbs = {};
				this.state.videos.map((content, index) => {
					if(content.acf.video_src == 'Vimeo'){
						let id=content.acf.video_id;
						let url ="https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/" + id;				
						fetch(url)
						.then(response => response.json())
						.then(response => {
							vimeoThumbs[index] = response.thumbnail_url;
						 	this.setState({'vimeoThumbs':vimeoThumbs});
						});
					}
				});
			}).bind(this)();
		});
	}

	videoPop(id, type){
		this.setState({'showVideo':true, 'popId' : id});
		if(type == 'vimeo'){
			this.setState({'videoPop': 'vimeo'});
		}else if(type == 'youtube'){
			this.setState({'videoPop': 'youtube'});
		}
		let tl = new TimelineMax();
		let target = '#' + this.state.randomId;
		tl.set(target, {display: 'block'})
		.to(target, .5, {
			opacity: 1
		})
	}

	videoClose(){
		let tl = new TimelineMax();
		let target = '#' + this.state.randomId;
		tl.to(target, .5, {
			opacity: 0
		})
		.set(target, {display: 'none'})
		.add(()=>{
			this.setState({'showVideo':false, 'popId' : null});
		});		
	}

	render(){

		let vimeoCount = 1;
		let videos = this.state.videos.map((content, index) => {
			let eyecatch = content.better_featured_image ? content.better_featured_image.media_details.sizes.medium_large.source_url : false;


			let id = content.acf.video_id;
			if(content.acf.video_src == 'Vimeo'){

				let vimeoThumb = this.state.vimeoThumbs[vimeoCount];
				vimeoCount++;
				let url = 'https://vimeo.com/' + id;
				let bgCss = {
					backgroundImage: 'url(' + vimeoThumb + ')',
				}
				let bgCss2 = {
					backgroundImage: 'url(' + eyecatch + ')',
				}

				return(
					<li key={index}>
						<div onClick={()=>this.videoPop(id, 'vimeo')}>
							<div className="hover">
								<MdAdd />
							</div>
							{!eyecatch &&
								<div className="thumbnail" style={bgCss}></div>
							}
							{eyecatch &&
								<div className="thumbnail" style={bgCss2}></div>
							}
						</div>
						<h3 className="en" dangerouslySetInnerHTML={{__html: content.title.rendered}}></h3>
					</li>
				);

			}else if(content.acf.video_src == 'Youtube'){
				let youtubeThumb = 'https://img.youtube.com/vi/' + id + '/0.jpg'
				let url = 'https://www.youtube.com/watch?v=' + id;
				let bgCss = {
					backgroundImage: 'url(' + youtubeThumb + ')',
				}
				let bgCss2 = {
					backgroundImage: 'url(' + eyecatch + ')',
				}
				return(
					<li key={index}>
						<div onClick={()=>this.videoPop(id, 'youtube')}>
							<div className="hover">
								<MdAdd />
							</div>
							{!eyecatch &&
								<div className="thumbnail" style={bgCss}></div>
							}
							{eyecatch &&
								<div className="thumbnail" style={bgCss2}></div>							
							}
						</div>
						<h3 className="en" dangerouslySetInnerHTML={{__html: content.title.rendered}}></h3>
					</li>
				);
			}
			
		});

		return(
			<ul className="videoList">
				{videos}
				<div 
					id={this.state.randomId} 
					className="videoPop"
				>
				{this.state.showVideo &&
					<div className="content">
						<div className="closer" onClick={()=>this.videoClose()}></div>

						<div className="close" onClick={()=>this.videoClose()}><span>CLOSE</span><IoIosCloseCircleOutline /></div>
						{this.state.videoPop == 'youtube' &&
							<div>
							<MQ query="(min-width:769px)">
								<Youtube 
									videoId={this.state.popId}
								/>
							</MQ>
							<MQ query="(max-width:768px)">
								<Youtube 
									videoId={this.state.popId} 
									opts={{
										width: '300',
										height: '200',
									}}
								/>
							</MQ>
							</div>
						}
						{this.state.videoPop == 'vimeo' &&
							<Vimeo videoId={this.state.popId} />
						}
					</div>
				}
				</div>
			</ul>
		);
	}

}













