import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {TimelineMax} from 'gsap';
import {outAnim} from '../Util';
import '../scss/More.scss';
import {
	FaAngleDoubleRight
} from 'react-icons/fa';


class More extends Component{
	constructor(props){
		super(props);
	}

	routeChange(to){
		function change(){
			let path = this.props.location.pathname;
			this.props.history.push(to);
		}
		outAnim(change.bind(this));
	}

	render(){
		return (
			<button
				className="more en"
				onClick={()=>
				this.routeChange(this.props.to)}
			>
				{this.props.text}
				<FaAngleDoubleRight className="icon" />
			</button>
		);
	}
	
}
export default withRouter(More);