/*
https://www.openprocessing.org/sketch/422191
https://www.openprocessing.org/sketch/537995
http://localhost:8888/shogofilm_wp/wp-json/wp/v2/video
*/

import React, {Component} from "react";
import {Parallax} from 'react-scroll-parallax';
import MQ from 'react-responsive';
import config from '../config';
import {inAnim, outAnim} from '../Util';
import Youtube from 'react-youtube';
import Vimeo from 'react-vimeo';
import P5Wrapper from '../Components/P5Wrapper';
import More from '../Components/More';
import topSketch from '../sketch/topSketch';
import '../scss/Top.scss';
import VideoList from '../Components/VideoList';
import TopVideo from '../assets/movie_top.mp4';
import TopPoster from '../assets/poster_top1.png';
import TopVideo2 from '../assets/movie_top2.mp4';
import TopPoster2 from '../assets/poster_top2.png';
import p_top from '../assets/top_main.jpg';
import p_sec1 from '../assets/top_sec1.jpg';
import p1 from '../assets/top_pg1.jpg';
import p2 from '../assets/top_pg2.jpg';
import p3 from '../assets/top_pg3.jpg';
import p4 from '../assets/top_pg4.jpg';
import bg1 from '../assets/bg_tile.png';

import GTM from 'react-gtm-module';
const tagManagerArgs = {
    gtmId: 'GTM-5WPXJ5G',
    dataLayer: {
    		dataLayerName: 'Top',
    }
}

const bgCss = {
	backgroundImage: 'url(' + bg1 + ')',
	backgroundRepeat: 'repeat',
}

class Top extends Component{
	constructor(props){
		super(props);
		this.state = {
			stateSketch: topSketch,
			latestNews: [],
		};
	}

	componentDidMount(){
		document.oncontextmenu = function(){ return false; };
		document.onselectstart = function(){ return false; };
		document.onmousedown = function(){ return false; };
		GTM.initialize(tagManagerArgs)
		this.fetchLatestNews();
		setTimeout(() => {inAnim();}, 1200);
	}

	fetchLatestNews(){
		let url = config.apiPath + 'posts?per_page=1';
		fetch(url).then(response=>response.json())
		.then(response=>{
			this.setState({
				'latestNews':response
			})
		})
	}


	toLatestNews(){
		function change(){
			this.props.history.push('news');
		}
		outAnim(change.bind(this));
	}


	render(){
		let topVideoClass = this.state.videoReady ? "ready" : "";
		let topNews = this.state.latestNews.map((content, index)=>{
			let rawDate = new Date(content.date_gmt);
			let date = 
				rawDate.getFullYear(rawDate) + '年' 
				+ (rawDate.getMonth()+1) + '月'
				+ rawDate.getDate() + '日';
			return(
				<div className="latest_news" key={index}>
					<h4 className="en">NEWS</h4>
					<p onClick={()=>this.toLatestNews()}>
						<time>{date}</time>
						<span dangerouslySetInnerHTML={{__html: content.title.rendered}}></span>
					</p>
				</div>
			);
		});
		return(
			<div id="top">
				<div id='topVideoContainer'>
					{<P5Wrapper sketch={this.state.stateSketch} />}
					<span className="tagline en">LIFE IS A WAVE</span>
					<MQ query="(min-width:769px)">
						<video id="topVideo"
						 autoPlay muted loop
						 type='video/mp4'
						 className={topVideoClass}
						 poster={TopPoster}
						>
							<source src={TopVideo} />
							<p>Your browser does not support HTML5 video.</p>
						</video>
					</MQ>
					<MQ query="(max-width:768px)">
						<img className="altVid" src={TopPoster} alt="" />
					</MQ>
					{topNews}
				</div>

				<section className="about main_clm clearfix" style={bgCss}>
					<div className="left">
						<img src={p_sec1} alt="" />
					</div>

					<div className="right">
						<MQ query="(min-width:1025px)">
							<Parallax 
								offsetYMax={15}
								disabled={false}
							>
							<p>カメラに囲まれ写真を撮り続けながら育った人生の中、スリランカでサーフィンに出会い、カリフォルニアでサーフカルチャーの渦中に身を委ねながら写真・映像制作を学びました。<br />それ以来、自分が魅せられ追いかけ続けている自然の素晴らしさ、ポジティブなエネルギーをもっと広めていけるように、日本をもっと元気にできるように、そしていつかは大きな波を起こせるようにと、カメラを握り続けています。</p>
							<More text="See More" to="/about" />
							</Parallax>
						</MQ>
						<MQ query="(max-width:1024px)">
							<p>カメラに囲まれ写真を撮り続けながら育った人生の中、スリランカでサーフィンに出会い、カリフォルニアでサーフカルチャーの渦中に身を委ねながら写真・映像制作を学びました。<br />それ以来、自分が魅せられ追いかけ続けている自然の素晴らしさ、ポジティブなエネルギーをもっと広めていけるように、日本をもっと元気にできるように、そしていつかは大きな波を起こせるようにと、カメラを握り続けています。</p>
							<More text="See More" to="/about" />
						</MQ>
					</div>
				</section>

				<section className="photo">
					<h3 className="en">Photography<span>写真撮影サービス</span></h3>
					<ul>
						<li style={{backgroundImage: 'url(' + p1 + ')'}}>
						<div className="bg"></div>
						<div className="content">
							<h3 className="en m">Surf/Sports<span>出張撮影</span></h3>
							<p>サーフィン、スキー・スノーボードなどの大会での撮影や個人・チームのプロモーション撮影など、全国各地へ出張いたします。</p>
							<More text="See More" to="/photo/sports" />
						</div>
						</li>

						<li style={{backgroundImage: 'url(' + p2 + ')'}}>
						<div className="bg"></div>
						<div className="content">
							<h3 className="en m">Wedding<span>前撮り</span></h3>
							<p>羽咋の豊かな自然や、金沢の歴史と趣ある景観を活かして、自然の雄大さや神聖な空気をまとった「一生の記念」を撮影します。</p>
							<More text="See More" to="/photo/wedding" />
						</div>
						</li>

						<li style={{backgroundImage: 'url(' + p3 + ')'}}>
						<div className="bg"></div>
						<div className="content">
							<h3 className="en m">Photo Tour<span>撮影ツアー</span></h3>
							<p>Offering photo tours to maximize your experience in Kanazawa. the unique scenic spots and activities that only locals would know will make your experience genuine.</p>
							<More text="See More" to="/photo/tour" />
						</div>
						</li>

						<li style={{backgroundImage: 'url(' + p4 + ')'}}>
							<div className="bg"></div>
							<div className="content">
								<h3 className="en m">Landscape<span>風景写真</span></h3>
								<p>国内、国外で旅をしながら風景の写真を収め続けています。また、独自の方法で出力した大型写真の展示販売会なども開催しています。</p>
								<More text="See More" to="/photo/landscape" />
							</div>
						</li>


					</ul>
				</section>

				<section className="video" style={bgCss}>
					<div className="clearfix main_clm">
						<MQ query="(min-width:769px)">
						<video
						 autoPlay muted loop
						 type='video/mp4'
						 poster={TopPoster2}
						>
							<source src={TopVideo2} />
							<p>Your browser does not support HTML5 video.</p>
						</video>
						</MQ>
						<MQ query="(max-width:768px)">
							<img className="altVid" src={TopPoster2} alt="" />
						</MQ>

						<div className="right">
							<h3 className="en">Videography<span>動画撮影・制作サービス</span></h3>
							<p>プロモーション動画やドキュメンタリーなどの映像制作などを行うビデオグラファーとしても活動しています。また、サーフィンやスキー・スノーボードなどの自然と向き合う撮影を得意とし、一瞬一瞬を大切にした撮影をこころがけています。</p>
							<More text="See More" to="/video" />
						</div>
					</div>
					<div className="videoList main_clm">
						<h4 className="en">Promotional</h4>
						<VideoList max="3" type="cm" />
					</div>
				</section>
			</div>
		);

	}
}

export default Top;