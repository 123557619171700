import React, {Component} from "react";
import {Helmet} from 'react-helmet';
import config from '../config';
import {inAnim} from '../Util';
import PageHeader from '../Components/PageHeader';
import VideoList from '../Components/VideoList';
import Aside from '../Components/Aside';
import headerImage from '../assets/header_vg.jpg';
import '../scss/Videography.scss';
import GTM from 'react-gtm-module';
const tagManagerArgs = {
    gtmId: 'GTM-5WPXJ5G',
    dataLayer: {
    		dataLayerName: 'Videography',
    }
}


export default class Videography extends Component{
	constructor(props){
		super(props);
		this.state = {
			videos: [],
			vimeo: []
		}
	}

	componentDidMount(){
		document.oncontextmenu = function(){ return false; };
		document.onselectstart = function(){ return false; };
		document.onmousedown = function(){ return false; };
		//after getting vimeo thumb
		GTM.initialize(tagManagerArgs)
		setTimeout(()=>{inAnim();}, 800);
	}


	render(){
		return(
			<div id="vg">
				<Helmet>
				  <title>Shogo Film | Videography</title>
				  <meta name="description" content="プロモーション動画やドキュメンタリーなどの映像制作などビデオグラファーとしても活動しています。" />
				</Helmet>
				<PageHeader title="Videography" img={headerImage} />
				<div className="typo_bg">
					<p className="sub_clm">一瞬、一瞬を大切にした撮影・制作を行います。<br />商品・サービスのプロモーション動画の制作からサーフィンやスノーボードの大会の記録、前撮りムービー、英語翻訳付きの動画製作などなんでも承ります。</p>
						<span className="en">MOMENT MATTERS</span>
				</div>
				<ul className="cm sub_clm clearfix">
					<h3 className="en m">Promotional</h3>
					<VideoList max="6" type="cm" />
				</ul>
				<ul className="sports sub_clm clearfix">
					<h3 className="en m">Surf / Sports</h3>
					<VideoList max="4" type="sports" />
				</ul>

				<Aside title="Service Price" sub="撮影料金" photography={false}>

					<h4>基本プラン（60秒まで）</h4>
					<dl>
						<div className="clearfix sub">
							<dt>企画・シナリオ</dt>
							<dd>¥25,000</dd>
						</div>
						<div className="clearfix sub">
							<dt>撮影</dt>
							<dd>¥25,000</dd>
						</div>
						<div className="clearfix sub">
							<dt>編集</dt>
							<dd>¥25,000</dd>
						</div>
						<div className="clearfix total">
							<dt>合計</dt>
							<dd>¥75,000</dd>
						</div>
					</dl>

					<h4>特別プラン（10分以上の映像製作）</h4>
					<dl>
						<div>
						<dt>料金</dt>
						<dd>¥450,000~</dd>
						</div>
					</dl>

					<h4>オプション</h4>
					<dl>
						<div>
						<dt>翻訳 + 字幕</dt>
						<dd>¥50,000~</dd>
						</div>
					</dl>
					<p className="caution">※英語から日本語もしくは日本語から英語</p>

					<h4>料金事例 オーパス英語学院 PV（60秒）</h4>
					<dl className="example">
						<div className="clearfix sub">
							<dt>基本プラン</dt>
							<dd>¥75,000</dd>
						</div>
						<div className="clearfix sub">
							<dt>ナレーション</dt>
							<dd>¥30,000</dd>
						</div>
						<div className="clearfix sub">
							<dt>モデル1名</dt>
							<dd>¥30,000</dd>
						</div>
						<div className="clearfix sub">
							<dt>BGM料金</dt>
							<dd>¥10,000</dd>
						</div>
						<div className="clearfix total">
							<dt>合計</dt>
							<dd>¥145,000</dd>
						</div>
					</dl>

					<h4>料金事例　MISTY MV（4分30秒）</h4>
					<dl className="example">
						<div className="clearfix sub">
							<dt>基本プラン x 4.5</dt>
							<dd>¥337,500</dd>
						</div>
						<div className="clearfix sub">
							<dt>モデル2名</dt>
							<dd>¥60,000</dd>
						</div>
						<div className="clearfix sub">
							<dt>アシスタント1名</dt>
							<dd>¥10,000</dd>
						</div>
						<div className="clearfix sub">
							<dt>修正料金</dt>
							<dd>¥30,000</dd>
						</div>
						<div className="clearfix sub">
							<dt>割引</dt>
							<dd>- ¥37,500</dd>
						</div>
						<div className="clearfix total">
							<dt>合計</dt>
							<dd>¥400,000</dd>
						</div>
					</dl>



					<h4>料金事例　虹体瞑想 英語翻訳 + 映像（12分44秒）</h4>
					<dl className="example">
						<div className="clearfix sub">
							<dt>特別プラン</dt>
							<dd>¥450,000</dd>
						</div>
						<div className="clearfix sub">
							<dt>翻訳 + 字幕</dt>
							<dd>¥50,000</dd>
						</div>
						<div className="clearfix total">
							<dt>合計</dt>
							<dd>¥500,000</dd>
						</div>
					</dl>
				</Aside>
			</div>
		);
	}
}

/*
CM
https://vimeo.com/57419608
https://www.youtube.com/watch?v=8JULuVF77LM
https://vimeo.com/29067340
https://vimeo.com/14868203
https://vimeo.com/39042459
https://www.youtube.com/watch?v=0Gnvtiik0lc&t=59s
https://www.youtube.com/watch?v=BqGftb3ZyOo&fbclid=IwAR3d__J7w8tM45ZI4sU3cFvkRq0O9x87muuQeEKgWTJZ9LBMc8xAXlYQlC8
*/

/*sports
https://vimeo.com/62917019
*/





